.rhymeComponent {
  text-align: justify;
  padding-left: 40px;
}

.modeBlock {
  width: 50%;
  display: flex;
  flex-direction: row;
  height: 52px;

  select {
    width: 50px;
  }
  .percent {
    padding-top: 20px;
    font-size: 0.8em;
    width: 50px;
  }

  .block {
    background-color: #ccc;
    border: chocolate;
    border-radius: 4px;
    width: 30%;
    padding: 10px;
    cursor: pointer;
    margin: 4px;
  }
  margin-bottom: 10px;
}


